<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-12">
          <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 section-head mb-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="section-head-title p-3 ps-0 mb-0">
              <font-awesome-icon 
                icon="star" 
                class="color-secondary"
              />
              Conoce las cifras más importantes de <strong>Reconoce</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6">
            <div class="section-head-filter d-flex justify-content-end">
            <div class="d-flex align-items-center pe-2 text-secondary">
              <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
            </div>
            <div 
              class="section-head-filter-input d-flex" 
              v-click-outside="handleCalendar">
              <div 
                class="calendar" 
                :class="calendar_side" 
                v-if="show_calendar"
              >
                <v-calendar 
                  color="blue" 
                  :max-date="max_date" 
                  :min-date="min_date" 
                  :attributes="attributes"
                  v-model="date_init" 
                  @dayclick="onDayClick" 
                />
                <button 
                  class="btn btn-custom-color-white reset-date"
                  @click="fecha_fin = 'Término',fecha_inicio = 'Inicio',getDash(),show_calendar=false"
                > Reiniciar
                </button>
                <div 
                  class="border-arrow" 
                  :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div 
                class="section-head-filter-input-start" 
                @click="openCalendar('start-date')">
                <span>{{ dateFormatymd(fecha_inicio) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
              <div 
                class="section-head-filter-input-end disabled" 
                v-if="fecha_inicio == 'Inicio'">
                <span>{{ dateFormatymd(fecha_fin) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
              <div 
                class="section-head-filter-input-end"
                v-if="fecha_inicio != 'Inicio'" 
                @click="openCalendar('end-date')">
                <span>{{ dateFormatymd(fecha_fin) }}</span>
                <div class="icon">
                  <img :src="img_calendar"/>
                </div>
              </div>
            </div>          
          </div>
          </div>
        </div>

<!--         <div class="dashboard-top">
          <h3 class="subtitulo">Conoce las cifras más importantes de Reconoce</h3>
          <div class="filter-container">
            <div class="title">Filtrar por período:</div>
            <div class="calendar-container" v-click-outside="handleCalendar">
              <div class="calendar" :class="calendar_side" v-if="show_calendar">
                <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
                  v-model="date_init" @dayclick="onDayClick" />
                <div class="delete-date"
                  @click="fecha_fin = 'Término',fecha_inicio = 'Inicio',getDash(),show_calendar=false">
                  Eliminar
                </div>
                <div class="border-arrow" :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div class="start" @click="openCalendar('start-date')">
                <span>{{ dateFormatymd(fecha_inicio) }}</span>
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div class="end unactive" v-if="fecha_inicio == 'Inicio'">
                <span>{{ dateFormatymd(fecha_fin) }}</span>
    
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
              <div class="end" v-if="fecha_inicio != 'Inicio'" @click="openCalendar('end-date')">
                <span>{{ fecha_fin }}</span>
                <div class="icon">
                  <img :src="img_calendar" alt="" />
                </div>
              </div>
            </div>
            
          </div>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">

            <!--CARD NUMBERS-->
            <div class="card card-numbers border-round-10 card-shadow mb-3">
              <div class="card-body">
                <div class="card-numbers-head d-flex align-items-center color-main">
                  <img class="card-numbers-head-icon" src="@/assets/img/reconoze/star.svg" alt="" />
                  <div class="card-numbers-head-num">{{ dash_data.total_reconocidos }}</div>
                  <div class="card-numbers-head-text">
                    Usuarios <br>
                    reconocidos
                  </div>
                </div>
                <div class="card-numbers-text mt-3">
                  {{ intro_text }}
                </div>
              </div>
            </div>

            <!--CARD PIE-->
            <div 
              class="card card-pie border-round-10 card-shadow mb-3 mb-lg-0" 
              v-show="showChart()">
              <div class="card-body">
                  <div class="row">
                    <div class="col-7">
                      <div class="card-pie-graph">
                        <apexchart
                          :key="key_dash" 
                          :options="chartOptions" 
                          :series="series">
                        </apexchart>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="card-pie-legend d-flex flex-column justify-content-center align-items-start ps-3">
                        <h5 class="mb-3">Estadísticas según relación</h5>
                        <div 
                          class="card-pie-legend-text d-flex align-items-center" 
                          v-for="(rela, i) in dash_data.total_relaciones" 
                          :key="i">
                          <div 
                            class="card-pie-legend-dot" 
                            :style="`background-color: ${chartOptions.colors[i]};`">
                          </div>
                          {{ rela.nombre_relacion }}
                        </div>
                      </div>                  
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">

              <!--CARD BARS-->
              <div 
                class="col-12 col-xl-6 mb-3" 
                v-for="(data, i) in dash_data.total_valores" :key="i">
                <div class="card card-shadow card-bars border-round-10">
                  <div class="card-body">
                    <div class="card-bars-title">{{ data.nombre_valor }}</div>
                    <div class="card-bars-count my-2">
                      <div class="card-bars-count-num pe-2">{{ data.cantidad }}</div>
                      <div class="card-bars-count-val">
                        Reconocimientos de {{ dash_data.total_reconocimientos }}
                      </div>
                    </div>
                    <div class="card-bars-bar">
                      <div 
                        class="card-bars-bar-back bg-blue-gray">
                      </div>
                      <div 
                        class="card-bars-bar-front bg-blue"
                        :style="`width:${data.porcentaje}%;`">
                      </div>
                    </div>
                  </div>
                </div>      
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import SectionTabs from "../Section/SectionTabs.vue";
import Spinner from "../Spinner.vue";
import { DASHBOARD_RECONOCE, GET_VALORES } from "@/apollo/queries";
export default {
  components: {
    // SectionTitle,
    SectionTabs,
    Spinner,
  },
  data() {
    return {
      show_spinner: false,
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-year",
        },
      ],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        subtitle:
          "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
          height: 'auto',
          fontFamily: "Product Sans Regular",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.35,
          },
        },
        dataLabels: {
          style: {
            colors: ["#fff"],
            fontSize: "16px",
            fontWeight: "bold",
          },
          dropShadow: {
            top: 0,
            left: 0,
            blur: 1,
            color: "#0072f7",
            opacity: 0.45,
          },
        },
        colors: ["#0072f7", "#7E88FD", "#B4B4FF"],
        legend: {
          show: false,
        },
        stroke: {
          show: false,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#fff"],
          width: 2,
          dashArray: 0,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "50%",
              background: "transparent",
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },

      valores: [],
      value_colors: [],
      dash_data: {},
      show_calendar: false,
      calendar_side: "",
      fecha_inicio: "Inicio",
      fecha_fin: "Término",
      max_date: "",
      min_date: "",
      key_dash: 1,
      img_calendar: require("@/assets/img/utilities/calendar.svg"),

      //FF
      intro_text: "Reconoce es una potente herramienta para mantener viva la cultura. Te recomendamos incorporarla en tus comunicaciones e ir analizando las estadísticas para evaluar su impacto."
    };
  },
  computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  methods: {
    showChart(){
      //console.log("Show chart",this.series)
      var suma = 0;
      for (let i = 0; i < this.series.length; i++) {
        const element = this.series[i];
        suma=suma+Number(element)
      }
      if(suma == 0){return false;}
      if(suma != 0){return true;}
    },  
    getValores() {
      this.value_colors = {};
      this.$apollo
        .query({
          query: GET_VALORES,
          variables: {
            id_empresa: this.id_empresa,
          },
        })
        .then((res) => {
          var resp = res.data.valoresReconocimientos;
          this.valores = resp;

          resp.forEach((el) => {
            this.value_colors[el.nombre_valor] = el.color;
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getDash() {
      this.show_spinner = true;
      this.$apollo
        .query({
          query: DASHBOARD_RECONOCE,
          variables: {
            id_empresa: this.id_empresa,
            fecha_inicio:
              this.fecha_inicio == "Inicio" ? "" : this.fecha_inicio,
            fecha_final: this.fecha_fin == "Término" ? "" : this.fecha_fin,
          },
        })
        .then((res) => {
          var resp = res.data.DashboardReconocimientos;
          this.dash_data = resp;
          var v_series = [];
          var v_chartOptions = [];
          for (let i = 0; i < resp.total_relaciones.length; i++) {
            var el = resp.total_relaciones[i];
            //console.log(resp.total_relaciones[i]);
            v_chartOptions.push(`${el.nombre_relacion}`);
            //console.log("v_series",v_chartOptions)
            v_series.push(Number(el.cantidad.split(".")[0]));
          }
          this.series = v_series;
          this.chartOptions.labels = v_chartOptions;

          setTimeout(() => {
          this.key_dash = this.key_dash + 1;}, 100);

          this.show_spinner = false;
        })
        .catch(() => {
          this.show_spinner = false;
        });
    },
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.fecha_inicio != "Inicio" ? new Date(this.fecha_inicio) : "";
        this.max_date = this.fecha_fin == "Término" ? "" : this.fecha_fin;
        this.min_date = "";
      } else {
        var min = new Date(this.fecha_inicio);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.fecha_inicio == "Inicio" ? "" : this.fecha_inicio;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.fecha_inicio = day.id;
      } else {
        this.fecha_fin = day.id;
      }
      if (this.fecha_inicio != "Inicio" && this.fecha_fin != "Término") {
        this.getDash();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
  },
  created() {
    this.getValores();
    this.getDash();
  }
};
</script>
